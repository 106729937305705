export const index = {
  cn: {
    logo: "https://imgoss.techfx88.com/www_img/www_upload/default17255164493011.png",
    banner: "https://imgoss.techfx88.com/www_img/www_upload/default17255167359125.png",
    banner1: "https://imgoss.techfx88.com/www_img/www_upload/default17255182895380.png",
    banner2: "https://imgoss.techfx88.com/www_img/www_upload/default17255253884643.png",
    banner3: "https://imgoss.techfx88.com/www_img/www_upload/default17255267302040.png",
    banner4: "https://imgoss.techfx88.com/www_img/www_upload/default17255854462342.png",
    banner5: "https://imgoss.techfx88.com/www_img/www_upload/default17255862864177.png",
    banner6: "https://imgoss.techfx88.com/www_img/www_upload/default17255889887284.png",
    banner7: "https://imgoss.techfx88.com/www_img/www_upload/default17255889876970.png",
    banner8: "https://imgoss.techfx88.com/www_img/www_upload/default17255889613863.png",
    banner9: "https://imgoss.techfx88.com/www_img/www_upload/default17255897185340.png",
    banner10: "https://imgoss.techfx88.com/www_img/www_upload/default17255897196297.png",
    banner11: "https://imgoss.techfx88.com/www_img/www_upload/default17255897197064.png",
    banner12: "https://imgoss.techfx88.com/www_img/www_upload/default17255996003103.png",
    banner13: "https://imgoss.techfx88.com/www_img/www_upload/default17276046366964.png",
    banner14: "https://imgoss.techfx88.com/www_img/www_upload/default17276046345165.png",
    banner15: "https://imgoss.techfx88.com/www_img/www_upload/default17258618878715.png",


    block2: "https://imgoss.techfx88.com/go_fd_do/fd-1711701954-l9r53b.png",
    block3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17117050772461.png",
    block4_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17117050773883.png",
    block4_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17117050781053.png",
    block4_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17117050788746.png",
    block4_4:
      "https://imgoss.techfx88.com/www_img/www_upload/default17117050786153.png",
    block4_5:
      "https://imgoss.techfx88.com/www_img/www_upload/default17117050787151.png",
    block4_6:
      "https://imgoss.techfx88.com/www_img/www_upload/default17117050796720.png",
    block5:
      "https://imgoss.techfx88.com/www_img/www_upload/default17117050799670.png",
    block6_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17117050793472.png",
    block6_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17117050707578.png",
    block6_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17117050706646.png",
    block6_4:
      "https://imgoss.techfx88.com/www_img/www_upload/default17117050713494.png",
    block7_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17266606111860.png",
    block7_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17117050717607.png",
    block7_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17117050712083.png",
    block7_4:
      "https://imgoss.techfx88.com/www_img/www_upload/default17117050727279.png",
    block7_5:
      "https://imgoss.techfx88.com/www_img/www_upload/default17117050729076.png",
    block7_6:
      "https://imgoss.techfx88.com/www_img/www_upload/default17117050728652.png",
    block7_7:
      "https://imgoss.techfx88.com/www_img/www_upload/default17117050739737.png",
    block7_8:
      "https://imgoss.techfx88.com/www_img/www_upload/default17117050734086.png",
    block7_9:
      "https://imgoss.techfx88.com/www_img/www_upload/default17117050731966.png",
    block7_10:
      "https://imgoss.techfx88.com/www_img/www_upload/default17117050735967.png",
    block7_11:
      "https://imgoss.techfx88.com/www_img/www_upload/default17117050742815.png",
    block7_12:
      "https://imgoss.techfx88.com/www_img/www_upload/default17117050745820.png",
    block7_13:
      "https://imgoss.techfx88.com/www_img/www_upload/default17117050745638.png",
    block7_14:
      "https://imgoss.techfx88.com/www_img/www_upload/default17117050757792.png",
    block7_15:
      "https://imgoss.techfx88.com/www_img/www_upload/default17117050756125.png",
    block7_16:
      "https://imgoss.techfx88.com/www_img/www_upload/default17117050757447.png",

    block8:
      "https://imgoss.techfx88.com/www_img/www_upload/default17183592781952.png",
    m_banner:
      "https://imgoss.techfx88.com/www_img/www_upload/default17183481297537.png",
    m1_banner:
      "https://imgoss.techfx88.com/www_img/www_upload/default17271436219369.png",
    m2_banner:
      "https://imgoss.techfx88.com/www_img/www_upload/default17271584035412.png",
    m3_banner:
      "https://imgoss.techfx88.com/www_img/www_upload/default17271590079748.png",
    m4_banner:
      "https://imgoss.techfx88.com/www_img/www_upload/default17271594533538.png",
    m5_banner:
      "https://imgoss.techfx88.com/www_img/www_upload/default17271590321888.png",
    m6_banner:
      "https://imgoss.techfx88.com/www_img/www_upload/default17271594533538.png",
    m7_banner:
      "https://imgoss.techfx88.com/www_img/www_upload/default17271601017389.png",
    m8_banner:
      "https://imgoss.techfx88.com/www_img/www_upload/default17271601004702.png",
    m9_banner:
      "https://imgoss.techfx88.com/www_img/www_upload/default17271660407432.png",

  },
  hk: {},
  en: {},
};
export const download = {
  cn: {
    banner: "https://imgoss.techfx88.com/go_fd_do/fd-1713941867-enidi9.png",
    block1_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17119395971806.png",
    block1_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17119395979780.png",
    block1_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17119395972050.png",
    block2_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17119395958539.png",
    block2_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17119395951872.png",
    block3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17119395989541.png",
    block3_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17119395964411.png",
    block3_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17119395954609.png",
    block3_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17119395965834.png",
    block3_4:
      "https://imgoss.techfx88.com/www_img/www_upload/default17119395967180.png",
    block3_5:
      "https://imgoss.techfx88.com/www_img/www_upload/default17119395946845.png",
    block3_6: "https://imgoss.techfx88.com/go_fd_do/fd-1711949170-ivbuy1.png",
  },
  hk: {},
  en: {},
};

export const insurance = {
  cn: {
    banner:
      "https://imgoss.techfx88.com/www_img/www_upload/default17119598539525.png",
    block2_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17119598539579.png",
    block2_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17119598548566.png",
    block2_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17119598547004.png",
    block2_4:
      "https://imgoss.techfx88.com/www_img/www_upload/default17119598542032.png",
    block2_5:
      "https://imgoss.techfx88.com/www_img/www_upload/default17119598559042.png",
    block2_6:
      "https://imgoss.techfx88.com/www_img/www_upload/default17119598555192.png",
    block3_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17119598489022.png",
    block3_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17119598485491.png",
    block3_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17119598496575.png",
    block3_4:
      "https://imgoss.techfx88.com/www_img/www_upload/default17119598494212.png",
    block3_5:
      "https://imgoss.techfx88.com/www_img/www_upload/default17119598495499.png",
    block3_6:
      "https://imgoss.techfx88.com/www_img/www_upload/default17119598498983.png",
    block4_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17119598504617.png",
    block4_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17119598503768.png",
    block4_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17119598509744.png",
    block4_4:
      "https://imgoss.techfx88.com/www_img/www_upload/default17119598514996.png",
    block4_5:
      "https://imgoss.techfx88.com/www_img/www_upload/default17119598513440.png",
    block4_6:
      "https://imgoss.techfx88.com/www_img/www_upload/default17119598512865.png",
    block4_7:
      "https://imgoss.techfx88.com/www_img/www_upload/default17119598521927.png",
    block4_8:
      "https://imgoss.techfx88.com/www_img/www_upload/default17119598526159.png",
    block5_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17119598527495.png",
    block5_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17119598529945.png",
    m_banner: "https://imgoss.techfx88.com/go_fd_do/fd-1715911855-oipb42.png",
  },
  hk: {},
  en: {},
};

export const insurance_savings = {
  cn: {
    block2_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17120252798620.png",
    block2_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17120252807522.png",
    block2_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17120252804683.png",
    block3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17120252803543.png",
    block3_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17120252816426.png",
    block3_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17120252817514.png",
    block3_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17120252813844.png",
    block3_4:
      "https://imgoss.techfx88.com/www_img/www_upload/default17120252811958.png",
    block3_5:
      "https://imgoss.techfx88.com/www_img/www_upload/default17120252822412.png",
    block4:
      "https://imgoss.techfx88.com/www_img/www_upload/default17120252823510.png",
    block4_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17120252821664.png",
    block4_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17120252831880.png",
    block4_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17120252837919.png",
    block4_4:
      "https://imgoss.techfx88.com/www_img/www_upload/default17120252831320.png",
  },
  hk: {},
  en: {},
};

export const insurance_crisis = {
  cn: {
    block2_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17120436862941.png",
    block2_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17120436878919.png",
    block2_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17120436877538.png",
    block3_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17120436874610.png",
    block3_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17120436883080.png",
    block3_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17120436885471.png",
    block3_4:
      "https://imgoss.techfx88.com/www_img/www_upload/default17120436882352.png",
    block3_5:
      "https://imgoss.techfx88.com/www_img/www_upload/default17120436899789.png",
    block3_6:
      "https://imgoss.techfx88.com/www_img/www_upload/default17120436894673.png",
    block3_7:
      "https://imgoss.techfx88.com/www_img/www_upload/default17120436895004.png",
    block3_8:
      "https://imgoss.techfx88.com/www_img/www_upload/default17120436894390.png",
    block4:
      "https://imgoss.techfx88.com/www_img/www_upload/default17120436909565.png",
    block4_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17120436903242.png",
    block4_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17120436904289.png",
    block4_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17120436915734.png",
    block4_4:
      "https://imgoss.techfx88.com/www_img/www_upload/default17120436912069.png",
    block4_5:
      "https://imgoss.techfx88.com/www_img/www_upload/default17120436916386.png",
  },
  hk: {},
  en: {},
};

export const insurance_lifespan = {
  cn: {
    block2_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121083496854.png",
    block2_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121083492436.png",
    block2_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121083482702.png",
    block3_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121083517844.png",
    block3_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121083515666.png",
    block3_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121083508302.png",
    block3_4:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121083505298.png",
    block3_5:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121083527373.png",
    block3_6:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121083516244.png",
    block4:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121083505862.png",
    block4_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121083525549.png",
    block4_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121083529288.png",
  },
  hk: {},
  en: {},
};
export const insurance_retirement = {
  cn: {
    block2_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121139677412.png",
    block2_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121139667924.png",
    block2_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121139674059.png",
    block3_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121139691197.png",
    block3_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121139685985.png",
    block3_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121139683669.png",
    block3_4:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121139699051.png",
    block3_5:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121139693235.png",
    block3_6:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121139685797.png",
    block4:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121139671816.png",
    block4_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121139691688.png",
  },
  hk: {},
  en: {},
};

export const insurance_medical = {
  cn: {
    block2_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121222634979.png",
    block2_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121222634519.png",
    block2_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121222649106.png",
    block3_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121222643593.png",
    block3_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121222653285.png",
    block3_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121222661308.png",
    block3_4:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121222643002.png",
    block3_5:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121222654406.png",
    block3_6:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121222652269.png",
    block4:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121222643209.png",
    block4_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121222666276.png",
    block4_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121222665610.png",
    block4_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121222652505.png",
  },
  hk: {},
  en: {},
};
export const insurance_strong_fund = {
  cn: {
    block2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121250748860.png",
    block3_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121250758346.png",
    block3_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121250752932.png",
    block3_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17121250751488.png",
  },
  hk: {},
  en: {},
};
export const insurance_header = {
  cn: {
    banner1: "https://imgoss.techfx88.com/go_fd_do/fd-1712027410-j94v3e.png",
    banner2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17120481651133.png",
    banner3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17120481667084.png",
    banner4:
      "https://imgoss.techfx88.com/www_img/www_upload/default17120481673997.png",
    banner5:
      "https://imgoss.techfx88.com/www_img/www_upload/default17120481654905.png",
    banner6:
      "https://imgoss.techfx88.com/www_img/www_upload/default17120481664900.png",
    m_banner1: "https://imgoss.techfx88.com/go_fd_do/fd-1715915739-negl8c.png",
    m_banner2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17159159645851.png",
    m_banner3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17159159644388.png",
    m_banner4:
      "https://imgoss.techfx88.com/www_img/www_upload/default17159159655528.png",
    m_banner5:
      "https://imgoss.techfx88.com/www_img/www_upload/default17159159659283.png",
    m_banner6:
      "https://imgoss.techfx88.com/www_img/www_upload/default17159159666514.png",
  },
};

export const fund = {
  cn: {
    banner: "https://imgoss.techfx88.com/go_fd_do/fd-1712639264-bvvsbe.png",
    block2_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17126289374259.png",
    block2_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17126289366346.png",
    block4:
      "https://imgoss.techfx88.com/www_img/www_upload/default17126289377022.png",
    block5_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17258597283845.png",
    block5_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17258597281802.png",
    block5_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17258597298930.png",
    block5_4:
      "https://imgoss.techfx88.com/www_img/www_upload/default17258597295022.png",
    block5_5:
      "https://imgoss.techfx88.com/www_img/www_upload/default17258597308938.png",
    block6_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17126289372417.png",
    block6_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17126289354197.png",
    block6_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17126289353824.png",
    block6_4:
      "https://imgoss.techfx88.com/www_img/www_upload/default17126289352237.png",
    m_banner: "https://imgoss.techfx88.com/go_fd_do/fd-1715933177-v2y7b9.png",
  },
  hk: {
    block2_2:"https://imgoss.techfx88.com/www_img/www_upload/default17266275225669.png",

  },
};
export const fund_group = {
  cn: {
    block2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17151365803154.png",
    block3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17151365808314.png",
    block4_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17151365802077.png",
    block4_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17151365806764.png",
    block4_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17151365811130.png",
    block4_4:
      "https://imgoss.techfx88.com/www_img/www_upload/default17151365813864.png",
    m_block2: "https://imgoss.techfx88.com/go_fd_do/fd-1715828092-vb0w4f.png",
    m_block3: "https://imgoss.techfx88.com/go_fd_do/fd-1715828146-isu5xd.png",
  },
};
export const fund_header = {
  cn: {
    banner1: "https://imgoss.techfx88.com/go_fd_do/fd-1712644280-97z65w.png",
    banner3: "https://imgoss.techfx88.com/go_fd_do/fd-1715136386-yy8b25.png",
    m_banner1: "https://imgoss.techfx88.com/go_fd_do/fd-1715933491-61y40v.png",
    m_banner3: "https://imgoss.techfx88.com/go_fd_do/fd-1715933621-pfxrer.png",
  },
};

export const wealth_planning = {
  cn: {
    banner:
      "https://imgoss.techfx88.com/www_img/www_upload/default17259319067913.png",
    block2_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141149051554.png",
    block2_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141149051093.png",
    block2_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141149058482.png",
    block3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141149064138.png",
    block3_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141149066221.png",
    block3_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141149067048.png",
    block3_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141149063435.png",
    block4:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141149072748.png",
    block5_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141149071604.png",
    block5_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141149071560.png",
    block5_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141149081856.png",
    block5_4:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141149089032.png",
    block6: "https://imgoss.techfx88.com/go_fd_do/fd-1714372484-87ckob.png",
    m_banner: "https://imgoss.techfx88.com/www_img/www_upload/default17273386801252.png",
    member1: "https://imgoss.techfx88.com/www_img/www_upload/default17259342047119.png",
    member2: "https://imgoss.techfx88.com/www_img/www_upload/default17259342042560.png",
    member3: "https://imgoss.techfx88.com/www_img/www_upload/default17259342055066.png",
    member4: "https://imgoss.techfx88.com/www_img/www_upload/default17259342053559.png",
    m_member_level1: "https://imgoss.techfx88.com/www_img/www_upload/default17273173722506.png",
    m_member_level2: "https://imgoss.techfx88.com/www_img/www_upload/default17273174029634.png",
    m_member_level3: "https://imgoss.techfx88.com/www_img/www_upload/default17273359961460.png",
    m_member_level4: "https://imgoss.techfx88.com/www_img/www_upload/default17273174056048.png",
    member_level1: "https://imgoss.techfx88.com/www_img/www_upload/default17259355862364.png",
    member_level2: "https://imgoss.techfx88.com/www_img/www_upload/default17259363925491.png",
    member_level3: "https://imgoss.techfx88.com/www_img/www_upload/default17259362757010.png",
    member_level4: "https://imgoss.techfx88.com/www_img/www_upload/default17259362992335.png",
    global_bg: "https://imgoss.techfx88.com/www_img/www_upload/default17261178224873.png",
    global_bg1: "https://imgoss.techfx88.com/www_img/www_upload/default17261247121904.png",
    global_bg2: "https://imgoss.techfx88.com/www_img/www_upload/default17261258231310.png",
    global_bg3: "https://imgoss.techfx88.com/www_img/www_upload/default17261258241141.png",
    global_bg4: "https://imgoss.techfx88.com/www_img/www_upload/default17261258247847.png",
    global_bg5: "https://imgoss.techfx88.com/www_img/www_upload/default17261391216454.png",
    global_bg6: "https://imgoss.techfx88.com/www_img/www_upload/default17291453288869.jpg",
    m_global_bg: "https://imgoss.techfx88.com/www_img/www_upload/default17274018967602.png",
    m1_global_bg: "https://imgoss.techfx88.com/www_img/www_upload/default17274046865601.png",
    m_global_bg1: "https://imgoss.techfx88.com/www_img/www_upload/default17274197768224.png",
    m1_global_bg1: "https://imgoss.techfx88.com/www_img/www_upload/default17274203385866.png",
    m2_global_bg1: "https://imgoss.techfx88.com/www_img/www_upload/default17274207944874.png",
    m3_global_bg1: "https://imgoss.techfx88.com/www_img/www_upload/default17274208171193.png",
    m4_global_bg1: "https://imgoss.techfx88.com/www_img/www_upload/default17274269672819.png",
    m5_global_bg1: "https://imgoss.techfx88.com/www_img/www_upload/default17291453288869.jpg"
  },
};
export const company_header = {
  cn: {
    banner1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141161017631.png",
    banner2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141161017631.png",
    banner3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141161017631.png",
    banner4:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141161017631.png",
    banner5:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141161017631.png",
    banner6:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141161017631.png",
    m_banner1: "https://imgoss.techfx88.com/go_fd_do/fd-1715931374-8rib8t.png",
    m_banner2: "https://imgoss.techfx88.com/go_fd_do/fd-1715931374-8rib8t.png",
    m_banner3: "https://imgoss.techfx88.com/go_fd_do/fd-1715931374-8rib8t.png",
    m_banner4: "https://imgoss.techfx88.com/go_fd_do/fd-1715931374-8rib8t.png",
    m_banner5: "https://imgoss.techfx88.com/go_fd_do/fd-1715931374-8rib8t.png",
    m_banner6: "https://imgoss.techfx88.com/go_fd_do/fd-1715931374-8rib8t.png",
  },
};
export const company_history = {
  cn: {
    banner:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141161017631.png",
  },
};

export const about_us = {
  cn: {
    block2_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141172385664.png",
    block2_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141172395232.png",
    block2_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141172391756.png",
  },
};

export const contact_us = {
  cn: {
    banner:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141176668304.png",
    block2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141176666449.png",
    block2_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141176668677.png",
    block2_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141176676641.png",
    block2_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141176671956.png",
    block2_4:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141176678799.png",
    block2_5:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141176679491.png",
  },
};

export const contact_header = {
  cn: {
    banner1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141176668304.png",
    m_banner1: "https://imgoss.techfx88.com/go_fd_do/fd-1715931818-8h6og5.png",
  },
};

export const social_service = {
  cn: {
    block2_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141181345466.png",
    block2_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141181337198.png",
    block2_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141181342923.png",
    block2_4:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141181335402.png",
  },
};

export const subsidiary = {
  cn: {
    block2_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141186205011.png",
    block2_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141186212004.png",
    block2_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141186202127.png",
  },
};

export const full_delegation = {
  cn: {
    banner: "https://imgoss.techfx88.com/go_fd_do/fd-1714373516-ay8yr3.png",
    block2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141192877653.png",
    block2_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141192876005.png",
    block2_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141192868686.png",
    block2_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141192877893.png",
    block3_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141192865634.png",
    block3_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141192856126.png",
    block3_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141192851049.png",
    block3_4:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141192861581.png",
    block4_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141192882005.png",
    block4_2: "https://imgoss.techfx88.com/go_fd_do/fd-1714377020-cnh84n.png",
  },
};

export const market_analysis = {
  cn: {
    block2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141200974361.png",
    block2_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141200959300.png",
    block2_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141200959155.png",
    block2_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141200963822.png",
    block2_4:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141200968371.png",
    block2_5:
      "https://imgoss.techfx88.com/www_img/www_upload/default17141200968979.png",
  },
};

export const stock_header = {
  cn: {
    banner1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17149757271806.png",
    banner2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17149757264344.png",
    banner3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17149757286821.png",
    banner4:
      "https://imgoss.techfx88.com/www_img/www_upload/default17149757288004.png",
    banner5:
      "https://imgoss.techfx88.com/www_img/www_upload/default17149757274011.png",
  },
};

export const stock_img = {
  cn: {
    block2_1: "https://imgoss.techfx88.com/go_fd_do/fd-1714976942-unmw8g.png",
    block2_2: "https://imgoss.techfx88.com/go_fd_do/fd-1714976980-5ezbz6.png",
    block2_3: "https://imgoss.techfx88.com/go_fd_do/fd-1714976994-uk6846.png",
    block3_1: "https://imgoss.techfx88.com/go_fd_do/fd-1714978929-2buu6d.png",
    block3_2: "https://imgoss.techfx88.com/go_fd_do/fd-1714978958-g1hswp.png",
    block3_3: "https://imgoss.techfx88.com/go_fd_do/fd-1714978975-iyr8yt.png",
  },
};

export const investment_header = {
  cn: {
    banner1: "https://imgoss.techfx88.com/go_fd_do/fd-1715063047-48liol.png",
    banner2: "https://imgoss.techfx88.com/go_fd_do/fd-1715063047-48liol.png",
    banner3: "https://imgoss.techfx88.com/go_fd_do/fd-1715063047-48liol.png",
    banner4: "https://imgoss.techfx88.com/go_fd_do/fd-1715063047-48liol.png",
    banner5: "https://imgoss.techfx88.com/go_fd_do/fd-1715063047-48liol.png",
    banner6: "https://imgoss.techfx88.com/go_fd_do/fd-1715063047-48liol.png",
  },
};

export const stock_trade = {
  cn: {
    block5_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17151567876088.png",
    block5_4:
      "https://imgoss.techfx88.com/www_img/www_upload/default17151567877747.png",
    block3: "https://imgoss.techfx88.com/go_fd_do/fd-1715158192-ed4d85.png",
    block5_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17151567883466.png",
    block5_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17151567895175.png",
    banner:
      "https://imgoss.techfx88.com/www_img/www_upload/default17266609364801.png",
    m_banner:
      "https://imgoss.techfx88.com/www_img/www_upload/default17272279599640.png",
    m1_banner:
      "https://imgoss.techfx88.com/www_img/www_upload/default17272292345938.png",
    m2_banner:
      "https://imgoss.techfx88.com/www_img/www_upload/default17272304746148.png",
    m3_banner:
      "https://imgoss.techfx88.com/www_img/www_upload/default17273399128251.png",
    m4_banner:
      "https://imgoss.techfx88.com/www_img/www_upload/default17272306237269.png",
    m5_banner:
      "https://imgoss.techfx88.com/www_img/www_upload/default17272306218280.png",
    m6_banner:
      "https://imgoss.techfx88.com/www_img/www_upload/default17272306201337.png",
    m7_banner:
      "https://imgoss.techfx88.com/www_img/www_upload/default17272308092223.png",
    m8_banner:
      "https://imgoss.techfx88.com/www_img/www_upload/default17272317675899.png",
    block4_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17151567909828.png",
    block4_4:
      "https://imgoss.techfx88.com/www_img/www_upload/default17151567903796.png",
    block4_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17151567902439.png",
    block4_5:
      "https://imgoss.techfx88.com/www_img/www_upload/default17151567916851.png",
    block4_6:
      "https://imgoss.techfx88.com/www_img/www_upload/default17151567917098.png",
    block4_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17151567918432.png",
    block2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17151567922039.png",
    m_block2_1: "https://imgoss.techfx88.com/go_fd_do/fd-1715761450-lbmuyh.png",
    m_block2_2: "https://imgoss.techfx88.com/go_fd_do/fd-1715761470-vkvdqq.png",
    company1: "https://imgoss.techfx88.com/www_img/www_upload/default17258465207827.png",
    company2: "https://imgoss.techfx88.com/www_img/www_upload/default17258465209699.png",
    company3: "https://imgoss.techfx88.com/www_img/www_upload/default17258465207182.png",
    company4: "https://imgoss.techfx88.com/www_img/www_upload/default17258465219451.png",
    stock1: "https://imgoss.techfx88.com/www_img/www_upload/default17258486844023.png",
    stock2: "https://imgoss.techfx88.com/www_img/www_upload/default17258486858984.png",
    stock3: "https://imgoss.techfx88.com/www_img/www_upload/default17258486863888.png",
    stock4: "https://imgoss.techfx88.com/www_img/www_upload/default17258498872341.png",
    stock5: "https://imgoss.techfx88.com/www_img/www_upload/default17258498804338.png",

  },
};

export const futures_header = {
  cn: {
    banner1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17153073788316.png",
    banner2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17153073788316.png",
  },
};

export const futures_trade = {
  cn: {
    banner:
      "https://imgoss.techfx88.com/www_img/www_upload/default17153073775735.png",
    banner2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17153073788316.png",
    block2_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17153073784723.png",
    block2_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17153073789425.png",
    block3_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17153073793974.png",
    block3_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17153073794134.png",
    block3_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17153073797744.png",
    block4_1:
      "https://imgoss.techfx88.com/www_img/www_upload/default17153073806632.png",
    block4_2:
      "https://imgoss.techfx88.com/www_img/www_upload/default17153073805200.png",
    block4_3:
      "https://imgoss.techfx88.com/www_img/www_upload/default17153073805859.png",
    block4: "https://imgoss.techfx88.com/go_fd_do/fd-1715309192-248teg.png",
    m_block4: "https://imgoss.techfx88.com/go_fd_do/fd-1715935889-zyij6f.png",
    block5:
      "https://imgoss.techfx88.com/www_img/www_upload/default17153073818692.png",
    m_banner:
      "https://imgoss.techfx88.com/www_img/www_upload/default17153073775735.png",
  },
};

export const server = {
  cn: {
    banner:
      "https://imgoss.techfx88.com/www_img/www_upload/default17183465224703.png",
    block1: "https://imgoss.techfx88.com/go_fd_do/fd-1715582189-qr3y90.png",
    m_banner: "https://imgoss.techfx88.com/go_fd_do/fd-1715910668-12gpln.png",
  },
};

export const activity = {
  cn: {
    banner: "https://imgoss.techfx88.com/go_fd_do/fd-1716258526-u70cgf.png",
  },
};

export const no_data_img =
  "https://imgoss.techfx88.com/go_fd_do/fd-1712820235-cjcgdg.png";
const cnImg = {
  index: {
    pc: {
      banner: "https://imgoss.techfx88.com/www_img/www_upload/default17255167359125.png",
      block2: "https://imgoss.techfx88.com/go_fd_do/fd-1711701954-l9r53b.png",
      block3:
        "https://imgoss.techfx88.com/www_img/www_upload/default17117050772461.png",
      block5:
        "https://imgoss.techfx88.com/www_img/www_upload/default17117050799670.png",
    },
    mo: {
      banner:
        "https://imgoss.techfx88.com/www_img/www_upload/default17183481297537.png",
      block2:
        "https://imgoss.techfx88.com/www_img/www_upload/default17183481304239.png",
      block3:
        "https://imgoss.techfx88.com/www_img/www_upload/default17183481302732.png",
      block5:
        "https://imgoss.techfx88.com/www_img/www_upload/default17183481297793.png",
    },
  },
  investmentReader: {
    pc: {},
  },
  fund_header: {
    pc: {
      banner1: "https://imgoss.techfx88.com/go_fd_do/fd-1712644280-97z65w.png",
      banner3: "https://imgoss.techfx88.com/go_fd_do/fd-1715136386-yy8b25.png",
      banner4:
        "https://imgoss.techfx88.com/www_img/www_upload/default17185912595606.png",
    },
    mo: {
      banner1: "https://imgoss.techfx88.com/go_fd_do/fd-1715933491-61y40v.png",
      banner3: "https://imgoss.techfx88.com/go_fd_do/fd-1715933621-pfxrer.png",
      banner4:
        "https://imgoss.techfx88.com/www_img/www_upload/default17186018152107.png",
    },
  },
  privateFund: {
    pc: {
      serve1:
        "https://imgoss.techfx88.com/www_img/www_upload/default17185912573877.png",
      serve2:
        "https://imgoss.techfx88.com/www_img/www_upload/default17185912578120.png",
      serve3:
        "https://imgoss.techfx88.com/www_img/www_upload/default17185912572447.png",
      serve4:
        "https://imgoss.techfx88.com/www_img/www_upload/default17185912569683.png",
      block1:
        "https://imgoss.techfx88.com/www_img/www_upload/default17185912602020.png",
      block2:
        "https://imgoss.techfx88.com/www_img/www_upload/default17185912563732.png",
    },
    mo: {
      serve1:
        "https://imgoss.techfx88.com/www_img/www_upload/default17186018161259.png",
      serve2:
        "https://imgoss.techfx88.com/www_img/www_upload/default17186018153963.png",
      serve3:
        "https://imgoss.techfx88.com/www_img/www_upload/default17186018162613.png",
      serve4:
        "https://imgoss.techfx88.com/www_img/www_upload/default17186018158073.png",
      block1:
        "https://imgoss.techfx88.com/www_img/www_upload/default17186018146651.png",
      block2:
        "https://imgoss.techfx88.com/www_img/www_upload/default17186018146080.png",
    },
  },
  concat: {
    pc: {
      banner1:
        "https://imgoss.techfx88.com/www_img/www_upload/default17141176668304.png",
      banner2:
        "https://imgoss.techfx88.com/www_img/www_upload/default17189374781083.png",

      bg1: "https://imgoss.techfx88.com/www_img/www_upload/default17189374791049.png",
    },
    mo: {
      banner1: "https://imgoss.techfx88.com/go_fd_do/fd-1715931818-8h6og5.png",
      banner2:
        "https://imgoss.techfx88.com/www_img/www_upload/default17189462367896.png",

      bg1: "https://imgoss.techfx88.com/www_img/www_upload/default17189462363894.png",
    },
  },
  stockHeader: {
    pc: {
      banner1:
        "https://imgoss.techfx88.com/www_img/www_upload/default17149757271806.png",
      banner2:
        "https://imgoss.techfx88.com/www_img/www_upload/default17149757264344.png",
      banner3:
        "https://imgoss.techfx88.com/www_img/www_upload/default17149757286821.png",
      banner4:
        "https://imgoss.techfx88.com/www_img/www_upload/default17149757288004.png",
      banner5:
        "https://imgoss.techfx88.com/www_img/www_upload/default17149757274011.png",
      banner6:
        "https://imgoss.techfx88.com/www_img/www_upload/default17149757274011.png",
    },
    mo: {
      banner1:
        "https://imgoss.techfx88.com/www_img/www_upload/default17195612492862.png",
      banner2:
        "https://imgoss.techfx88.com/www_img/www_upload/default17195612514805.png",
      banner3:
        "https://imgoss.techfx88.com/www_img/www_upload/default17195612516676.png",
      banner4:
        "https://imgoss.techfx88.com/www_img/www_upload/default17195612501751.png",
      banner5:
        "https://imgoss.techfx88.com/www_img/www_upload/default17195612503362.png",
      banner6:
        "https://imgoss.techfx88.com/www_img/www_upload/default17195614374038.png",
    },
  },
};
export const imgList = {
  cn: cnImg,
  hk: cnImg,
  en: cnImg,
};
